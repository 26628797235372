@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";

@font-face {
    font-family: "pirataone";
    src: url("/PirataOne-Regular.ttf");
}

@font-face {
    font-family: "hightower";
    src: url("/HighTower.ttf");
}

$primary: #383132;
$warning: #d3cbbb;
$danger: #bf363b;
$success: #8f7b3d;
$info: #7c7d7b;
$link: $primary;

$input-color: $grey-darker;
$input-placeholder-color: rgba($input-color, 0.8) !default;

// Don't waste space on desktop and higher.
$modal-content-width: calc(100% - 96px);
$navbar-tab-active-border-bottom-color: $info;

// Import the rest of Bulma:
@import "bulma/bulma.sass";

$duration: 0.1s;
$hex-size: 225px;

html {
    height: 100%;
}

body {
    background-image: url('/map.jpg');
    min-height: 100vh;
}

#root {
    min-height: 100vh;

    .logo {
        line-height: 0.9;
    }

    .is-dark a {
        color: $success;
    }

    .navbar a {
        color: $light;
    }

    .loader {
        border-left-color: black;
        border-bottom-color: black;
    }
}

footer {
    line-height: 1.3;

    p {
        margin-bottom: 0.8em;
    }
}


.Home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .Content {
        flex: 1;
    }

    .hero {
        h1 {
            line-height: 1;
            margin-bottom: 0.8em;
        }

        h2 {
            line-height: 1;
        }

        .container {
            padding: 0 1.5rem;
        }
    }

    table {
        background: transparent;

        &.is-striped tbody tr:not(.is-selected):nth-child(2n) {
            background: rgba(200, 200, 200, 0.5);
        }

        tr.is-selected a {
            color: $primary;
        }

        td {
            vertical-align: middle;
        }
    }
}


h1, h2, h3, h4, h5 {
    font-family: "pirataone", sans-serif;
}

h1 {
    @extend .title;
    @extend .is-3;
}

h2 {
    @extend .title;
    @extend .is-4;
}

h3 {
    @extend .title;
    @extend .is-5;
}

.icon.is-inverted {
    filter: invert(100%);
}


.ScenarioViewer {
    img {
        // Bulma sets nice styles for images which we don't want.
        max-width: unset;
        height: unset;
    }

    .open-door {
        opacity: 0.8;
        filter: grayscale(1);
    }

    .field-label {
        // on 769px and higher (small but not yet mobile), make inputs take
        // more space.
	    margin-right: 0.5rem;
    }
}

.Main {
    padding: 0.5rem;

    .Container {
        height: calc(100vh - 4.5rem);

        @include mobile() {
            height: 60vh;
        }

        border: 1px dashed $success;

        overflow: scroll;

        position: relative;

        .Viewport {
            position: absolute;
            overflow: hidden;

            .Content {
                transform-origin: 0 0;

                .Things {
                    transition: transform $duration;
                }

                svg {
                    position: absolute;
                    left: 0; top: 0; right: 0; bottom: 0;
                    pointer-events: none;
                    overflow: visible;
                }
            }
        }
    }
}


.thing-container {
    position: absolute;
    transition: transform $duration;
}

.thing-container.is-selected {
    outline: 10px dotted green;
}

.thing-container .contents {
    position: absolute;
    left: 0;
    top: 0;
    width: $hex-size;
    height: $hex-size;
}

.thing-container .addition {
    position: absolute;
    left: 0;
    top: 0;
    width: $hex-size;
    font-family: "hightower";
    font-size: 58px;
    cursor: default;
}

.thing-container .label {
    text-align: center;
    color: $dark;
    top: 32px;
}

.thing-container .amount {
    text-align: center;
    color: yellow;
    left: 75px;
    top: 120px;
    font-size: 72px;

    height: 72px;
    width: 72px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid $dark;
    text-shadow: 1px 1px 0 yellow;
}

.thing-container .amount span {
    position: relative;
    top: -20px;
}

.thing-container .hp {
    text-align: center;
    color: red;
    top: 80px;
    left: 0px;

    height: 72px;
    width: 72px;
    border-radius: 0.25em;
    border: 0.1em solid $dark;
    background: rgba(255,255,255, 0.7);
    text-shadow: 1px 1px 0 $dark;
}

.thing-container .hp span {
    position: relative;
    top: -10px;
}

.thing-container .standeeNumber {
    top: 90px;
    width: 125px;
    left: 50px;
    filter: grayscale(1);
}

.thing-container .redCircleLabel {
    top: 46px;
    width: 225px;
    height: 225px;
    font-family: "pirataone";
    color: white;
    font-size: 84px;
    text-align: center;
    vertical-align: middle;
}

.thing-container .icon {
    width: 75px;
    opacity: 0.9;
}

.navbar {
    @extend .hero.is-bold;
}

.Setting {
    .textarea {
        min-height: 38em;
    }

    .label {
        font-weight: normal;
    }
}

.ThingSelector {
    ul {
        list-style-type: none;
        padding: 0;
    }

    .ThingSelector_Thing {
        color: $link;
        cursor: pointer;
        margin-left: 1em;
    }

    .ThingSelector_Thing.is-active {
        font-weight: bold;
    }
}


.NewThingSelector .controls {
    margin-bottom: 1em;
}

.NewThingSelector .header {
    font-size: larger;
    font-weight: bold;
    display: inline;
    margin-right: 1em;
}

.NewThingSelector .controls form {
    display: inline;
}

.NewThingSelector ul {
    list-decoration: none;
    padding: 0;
}

.NewThingSelector li {
    float: left;

    margin: 0.5em;
    padding: 0.5em;
    width: 8em;
    height: 8em;
    list-style-type: none;
    border: 1px dotted purple;
    position: relative;
}

.NewThingSelector li:hover {
    background-color: #f7f;
    cursor: pointer;
}

.NewThingSelector li.disabled {
    background-color: #777;
}

.NewThingSelector li.disabled img {
    filter: grayscale(1);
}

.NewThingSelector li.disabled:hover {
    cursor: default;
}

.NewThingSelector .thing-name {
    position: absolute;
    text-align: center;
    bottom: 0.3em;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.3);
}

.NewThingSelector img {
    position: absolute;
    top: -50%;
    bottom: -35%;
    left: -50%;
    right: -50%;
    max-width: 90%;
    max-height: 75%;
    margin: auto;
}


.SelectedThingChangers {
    border-spacing: 0;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 1em;
    user-select: none;

    td {
        width: 2em;
        height: 2em;
        border: 1px dotted $dark;
        vertical-align: middle;

        .int {
            text-align: center;
        }

        &.active {
            cursor: pointer;

            &:hover {
                background: $success;
            }
        }
    }
}

.PartyEditor .pcs  {
    overflow: hidden; /* to contain floats */
    margin-bottom: 1em;
    display: flex;
    flex-wrap: wrap;
}

.PcEditor {
}

.PcEditor section:not(:last-child) {
    margin-bottom: 0.5em;
}

.ProfessionSelector {
    display: flex;
    flex-direction: row;
}

.ProfessionSelector .choice {
    width: 3em;
    background: white;
    border-radius: 0.5em;
    border: 1px solid $dark;
    margin: 0.2em;
    cursor: pointer;
}

.ProfessionSelector .choice.is-active {
    background: #7f7;
    box-shadow: inset 0px 0px 5px #c1c1c1;
}

.ProfessionSelector .choice:hover {
    box-shadow: 0 0 0.5em green;
}

.ProfessionSelector .choice img {
    width: 100%;
    vertical-align: middle;
}


$cardMargin: 2px;

$amCardWidth: 100px;
$amCardHeight: $amCardWidth * 0.665;
$amCardOverlap: $amCardWidth * 0.3;

$abilityCardWidth: 104px;
$abilityCardHeight: ($abilityCardWidth - 4px) * 1.5 + 4px;
$abilityCardOverlap: $abilityCardWidth * 0.15;
$abilitySquishedCardOverlap: $abilityCardWidth * 0.4;

.PcAreaSection {
    margin: 1rem 0;
}

@mixin zone {
    border: 1px solid $dark;
    border-radius: 0.5em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: $cardMargin;
}

.Discard img {
    opacity: 0.7;
}

.AmZone {
    @include zone;
    min-height: $amCardHeight + $cardMargin * 2;
    min-width: $amCardWidth + $cardMargin * 2;
    padding-left: $amCardOverlap + $cardMargin;
    margin-bottom: 0.5rem;
}

.AmZone.Pile {
    // XXX no idea why 3, logically it should be 2 but 2 displays incorrectly.
    width: $amCardWidth + $cardMargin * 3;
}

.AmZone .AmCard {
    margin-left: -$amCardOverlap;
}

.AmCard {
    position: relative;
    width: $amCardWidth;
    height: $amCardHeight;
}

.AmCard > img {
    width: 100%;
}

.AmCard .Label {
    position: absolute;
    left: 0;
    top: $amCardHeight * 0.3;
    background: rgba($info, 0.8);
    padding: 0 0.5em;
    border-radius: 0.5em;
    color: $light;
    font-family: "hightower";
    font-size: $amCardHeight * 0.3;
}

.AbilityZone {
    @include zone;
    min-height: $abilityCardHeight + $cardMargin * 2;
    min-width: $abilityCardWidth + $cardMargin * 2;
    padding-left: $abilityCardOverlap + $cardMargin;
    margin-bottom: 0.5rem;
}

.AbilityZone.isSquished {
    padding-left: $abilitySquishedCardOverlap + $cardMargin;
}

.AbilityZone.Pile {
    width: $abilityCardWidth + $cardMargin * 2;
}

.AbilityZone.Two {
    width: ($abilityCardWidth * 2 - $abilityCardOverlap) + $cardMargin * 3;
}

.AbilityZone .AbilityCard {
    margin-left: -$abilityCardOverlap;
}

.AbilityZone.isSquished .AbilityCard {
    margin-left: -$abilitySquishedCardOverlap;
}

.AbilityZone.isTopRow .AbilityCard {
    transform-origin: 50% 10%;
}

.AbilityCard {
    position: relative;
    transition: transform $duration;
    width: $abilityCardWidth;
    height: $abilityCardHeight;
    border-radius: 0.5rem;
    border: 2px solid transparent;
}

.AbilityCard.isSelected {
    border: 2px solid $success;
    box-shadow: 0px 0px 1rem $success;
    filter: brightness(1.25);
}

.AbilityCard > img {
    width: $abilityCardWidth - 4px;
    height: $abilityCardHeight - 4px;
}

.AbilityCard:hover {
    transform: scale(2.5);
    z-index: 1;
}

.vertical-buttons {
    display: flex;
    flex-direction: column;
}

.vertical-buttons > .button {
    margin-bottom: 0.3rem;
}

.container.PcAreaContent {
    margin-bottom: 0;
}

.centered-in-row {
    margin-top: 2.5rem; // XXX this is a hack to visually center it
}

.Sidebar {
    padding: 0.5em;
}

.deny {
    animation: deny 300ms;
}

@keyframes deny {
    0% {
        filter: drop-shadow(0 0 20px $danger);
    }
    100% {
        filter: none;
    }
}

.section-small {
    padding: 1rem 1.5rem;
}
